import React from "react"
import styled from "styled-components"

export default function CustomForm({ formName, fields }) {
  return (
    <Form
      name={formName}
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      //   support files and images
      encType="multipart/form-data"
    >
      <input type="hidden" name="form-name" value={formName} />
      {fields.map((field, index) => (
        <div key={index}>
          <label htmlFor={field.name}>{field.label}</label>
          {field.type === "textarea" ? (
            <textarea
              {...field}
              name={field.name}
              id={field.name}
              rows="6"
              placeholder={field.placeholder}
            />
          ) : (
            <input
              {...field}
              type={field.type}
              name={field.name}
              id={field.name}
              placeholder={field.placeholder}
            />
          )}
        </div>
      ))}
      <button>Submit</button>
    </Form>
  )
}

const Form = styled.form`
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 1rem;
    background: none;
    // border: solid 2px
    //   ${props =>
      props.theme.primary ? props.theme.primary : "rgb(202,54,37)"};
    border-radius: 5px;
    box-shadow: none;
  }
  > *:last-child {
    margin-bottom: 0;
  }
  > div {
    display: flex;
    flex-direction: column;
  }
  > input,
  > textarea {
    padding: 0.5rem;
    color: #111;
    &::placeholder {
      color: ${props =>
        props.theme.secondary ? props.theme.secondary : "rgb(202,54,37)"};
    }
  }
  > button {
    background: ${props =>
      props.theme.secondary ? props.theme.secondary : "rgb(202,54,37)"};
    padding: 0.75rem;
    border: none;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
  }
`
