import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import axios from "axios"
import jsonpAdapter from "axios-jsonp"
import { Link } from "gatsby"
// import SocialIcons from "../components/SocialIcons/SocialIcons"
import SocialIcons from "../components/SocialIcons/SocialIcons"
import Hours from "../components/hours/hoursOld"
import ContactForm from "../components/forms/ContactForm"
import getGoogleStr from "../helpers/getGoogleStr"
import CustomForm from "../components/forms/CustomForm"
import useStories from "../hooks/useStories"
// import Hours from "../components/hours/Hours"

export default function Careers() {
  const { getStoriesByName } = useStories()
  const [contactData, setContactData] = useState({
    contactData: null,
    isLoading: true,
  })
  const story = getStoriesByName("careers page story")[0]
  console.log("story", story)

  const id = "bzn-yO3xgUsKQCS7GWg0Q2ewbQ"

  useEffect(() => {
    axios({
      url: `https://data.prod.gonation.com/profile/getname/?profile_id=${id}`,
      adapter: jsonpAdapter,
    }).then(res => {
      setContactData({
        ...contactData,
        contactData: res.data,
        isLoading: false,
      })
    })
  }, [])

  const renderAddress = () => {
    const {
      name,
      street,
      city,
      state,
      zip,
      phone,
      links: { facebook, instagram, twitter },
    } = contactData.contactData
    return (
      <Address>
        <h1>{name}</h1>
        <a
          href={getGoogleStr(name, street, city, zip, state)}
          target="_blank"
          rel="noopener"
        >
          <p>{street}</p>
          <p>
            {city}, {state}
          </p>
          <p>{zip}</p>
        </a>

        <br></br>
        <a href={`tel:${phone}`}>{phone}</a>

        {/* <SocialIcons
          instagram={instagram}
          facebook={facebook}
          twitter={twitter}
        /> */}
      </Address>
    )
  }

  return (
    <Layout pageTitle="careers" noHero>
      <Page>
        <SectionTitle>Careers</SectionTitle>
        {story && (
          <StoryContainer>
            <div dangerouslySetInnerHTML={{ __html: story.bodyHTML }} />
          </StoryContainer>
        )}
        <ContactDetailsContainer>
          <CustomForm
            formName={"careers"}
            fields={[
              {
                type: "text",
                name: "name",
                placeholder: "Full Name",
                label: "Full Name",
                required: true,
              },
              {
                type: "email",
                name: "email",
                placeholder: "Email",
                label: "Email",
                required: true,
              },
              {
                type: "tel",
                name: "phone",
                placeholder: "Phone",
                label: "Phone",
                required: true,
              },
              {
                type: "file",
                name: "cover",
                placeholder: "Upload Cover Letter",
                label: "Upload Cover Letter",
                multiple: false,
              },
              {
                type: "file",
                name: "resume",
                placeholder: "Upload Resume",
                label: "Upload Resume",
                multiple: false,
              },
            ]}
          />
        </ContactDetailsContainer>
      </Page>
    </Layout>
  )
}

const Page = styled.section`
  background-color: ${props =>
    props.theme.light ? props.theme.primary : "grey"};

  padding-top: 12rem;
  padding-bottom: 8rem;
`

const ContactDetailsContainer = styled.div`
  background-color: white;
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 5px;
`

const ContactDetails = styled.div`
  padding: 1rem;
  text-align: center;
  background-color: ${props =>
    props.theme.primary ? props.theme.primary : "grey"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 767px) {
    width: 50%;
    padding: 1rem;
    margin: 0;
  }
`

const Address = styled.div`
  a {
    color: #fff;
  }
  > * {
    color: white;
    margin-bottom: 0.25rem;
    text-align: center;
  }
`

const FormContainer = styled.div`
  padding: 1rem;
  background-color: white;

  p {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 0.5rem;
  }
  @media (min-width: 767px) {
    width: 50%;
    padding: 1rem;
    margin: 0;
  }
`

const SectionTitle = styled.h3`
  font-size: 1.75rem;
  font-weight: bold;
  color: ${props => (props.theme.secondary ? "white" : "black")};
  margin-bottom: 0.75rem;
  text-align: center;
`

const ContactHoursMap = styled.div`
  @media (min-width: 767px) {
    padding: 2rem 0rem;
    padding-top: 0;
    padding-top: 0;
    display: flex;
    max-width: 1024px;
    margin: 0 auto;
  }
`

const MapContainer = styled.div`
  width: 100%;
  @media (min-width: 767px) {
    width: 50%;
  }
`
const Map = styled.iframe`
  width: 100%;
  min-height: 40vh;
  height: 100%;
`

const FormText = styled.div`
  padding: 1.5rem;
  a {
    font-weight: bold;
  }
`

const StoryContainer = styled.div`
  background-color: white;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 5px;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 2rem;
  p {
    margin-bottom: 1rem;
  }
`
