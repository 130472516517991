// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-beer-js": () => import("./../../../src/pages/beer.js" /* webpackChunkName: "component---src-pages-beer-js" */),
  "component---src-pages-beer-list-js": () => import("./../../../src/pages/beer-list.js" /* webpackChunkName: "component---src-pages-beer-list-js" */),
  "component---src-pages-book-a-party-js": () => import("./../../../src/pages/book-a-party.js" /* webpackChunkName: "component---src-pages-book-a-party-js" */),
  "component---src-pages-brewery-js": () => import("./../../../src/pages/brewery.js" /* webpackChunkName: "component---src-pages-brewery-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dockside-all-year-js": () => import("./../../../src/pages/dockside-all-year.js" /* webpackChunkName: "component---src-pages-dockside-all-year-js" */),
  "component---src-pages-drinks-js": () => import("./../../../src/pages/drinks.js" /* webpackChunkName: "component---src-pages-drinks-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-golf-js": () => import("./../../../src/pages/golf.js" /* webpackChunkName: "component---src-pages-golf-js" */),
  "component---src-pages-gonationqr-js": () => import("./../../../src/pages/gonationqr.js" /* webpackChunkName: "component---src-pages-gonationqr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marina-js": () => import("./../../../src/pages/marina.js" /* webpackChunkName: "component---src-pages-marina-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-mug-club-js": () => import("./../../../src/pages/mug-club.js" /* webpackChunkName: "component---src-pages-mug-club-js" */),
  "component---src-pages-oktoberfest-js": () => import("./../../../src/pages/oktoberfest.js" /* webpackChunkName: "component---src-pages-oktoberfest-js" */),
  "component---src-pages-private-events-js": () => import("./../../../src/pages/private-events.js" /* webpackChunkName: "component---src-pages-private-events-js" */),
  "component---src-pages-reservations-js": () => import("./../../../src/pages/reservations.js" /* webpackChunkName: "component---src-pages-reservations-js" */),
  "component---src-pages-reserve-an-igloo-js": () => import("./../../../src/pages/reserve-an-igloo.js" /* webpackChunkName: "component---src-pages-reserve-an-igloo-js" */),
  "component---src-pages-superbowl-specials-js": () => import("./../../../src/pages/superbowl-specials.js" /* webpackChunkName: "component---src-pages-superbowl-specials-js" */),
  "component---src-pages-table-js": () => import("./../../../src/pages/table.js" /* webpackChunkName: "component---src-pages-table-js" */),
  "component---src-pages-tour-the-venue-js": () => import("./../../../src/pages/tour-the-venue.js" /* webpackChunkName: "component---src-pages-tour-the-venue-js" */),
  "component---src-templates-article-menu-template-js": () => import("./../../../src/templates/ArticleMenuTemplate.js" /* webpackChunkName: "component---src-templates-article-menu-template-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/ArticleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */)
}

